import { type LocalePathFunction } from 'vue-i18n-routing';

const getContentCardConfig = (localePath: LocalePathFunction) => {
  // Promotion for tick prescription on cat food categories and dog food categories
  const contentCardConfigTickPrescription = {
    showForAttributes: [
      {
        name: 'categoriesPath',
        value: 'djurtyp/katt/kattfoder/veterinarfoder',
      },
      {
        name: 'categoriesPath',
        value: 'djurtyp/katt/kattfoder/torrfoder',
      },
      {
        name: 'categoriesPath',
        value: 'djurtyp/katt/kattfoder/vatfoder',
      },
      {
        name: 'categoriesPath',
        value: 'djurtyp/hund/hundfoder/veterinarfoder',
      },
      {
        name: 'categoriesPath',
        value: 'djurtyp/hund/hundfoder/vatfoder',
      },
      {
        name: 'categoriesPath',
        value: 'djurtyp/hund/hundfoder/torrfoder',
      },
    ],
    name: 'pharma',
    image: {
      desktop: 'pharma-desktop.webp',
      mobile: 'pharma-mobile.webp',
    },
    link: localePath('/djurapotek-hamta-ut-lakemedel-till-husdjur'),
  };

  // Promotion for prescriptions on pharmacy category
  const contentCardConfigPharmacy = {
    showForAttributes: [
      {
        name: 'categoriesPath',
        value: 'djurtyp/apotek',
      },
    ],
    name: 'prescriptions',
    image: {
      desktop: 'prescriptions-desktop.webp',
      mobile: 'prescriptions-mobile.webp',
    },
    link: localePath({ name: 'shop-login' }),
  };

  return {
    sv: [contentCardConfigTickPrescription, contentCardConfigPharmacy],
  };
};

export { getContentCardConfig };
